import { Flag, ProductVariantRaw, StockStatus } from '~/constants/types/algolia';
import {
  NorceFlagCodes,
  NorceFlagGroupCodes,
  NorceParametricCodes,
  NorceProductStatusCodes,
  NorceWarehouseCodes
} from '~/constants/norceCodes';
import useStripFlagSuffix from '~/composeables/useStripFlagSuffix';
import {useGlobalContentStore} from "~/store/globalContent";
const { stripFlagSuffix } = useStripFlagSuffix();

export type ProductVariantModel = ProductVariant & ProductVariantRaw
export default class ProductVariant {
  _ProductVariantRaw: ProductVariantRaw;
  constructor(ProductVariant: ProductVariantRaw) {
    this._ProductVariantRaw = ProductVariant;
    return new Proxy(this, {
      get(target, name, receiver) {
        if (Reflect.has(target, name)) {
          return Reflect.get(target, name, receiver);
        }
        return Reflect.get(target._ProductVariantRaw, name, receiver);
      },
    });
  }

  static create(arg: ProductVariantRaw) {
    return new ProductVariant(arg) as ProductVariantModel;
  }

  // converted from old model, should be deleted

  /**
   * @deprecated - use this.definingParametrics
   */
  get variantParametrics() {
    return this._ProductVariantRaw.definingParametrics;
  }

  parametricByCode(code: string) {
    return this._ProductVariantRaw.definingParametrics.find(p => p.code === code);
  }

  get priceInfo() {
    return {
      price: this._ProductVariantRaw.priceInfo?.price || '',
      priceDisplay: this._ProductVariantRaw.priceInfo?.priceDisplay || '',
      priceBeforeDiscount: this._ProductVariantRaw.priceInfo?.priceBeforeDiscount || '',
      priceBeforeDiscountDisplay: this._ProductVariantRaw.priceInfo?.priceBeforeDiscountDisplay || '',
      priceBeforeVat: this._ProductVariantRaw.priceInfo?.priceBeforeVat || '',
      priceBeforeVatDisplay: this._ProductVariantRaw.priceInfo?.priceBeforeVatDisplay || '',
      priceBeforeDiscountBeforeVatDisplay: this._ProductVariantRaw.priceInfo?.priceBeforeDiscountBeforeVatDisplay || '',
      vatRate: this._ProductVariantRaw.priceInfo?.vatRate || 0,
      isOnSale: this._ProductVariantRaw.priceInfo.isOnSale ?? (this._ProductVariantRaw.priceInfo.price !== this._ProductVariantRaw.priceInfo.priceBeforeDiscount),
    };
  }

  get status() {
    return this._ProductVariantRaw.status ?? '';
  }

  get stockStatus(): StockStatus | null {
    return this._ProductVariantRaw.stockStatuses[0] ?? null;
  }

  get isActive(): boolean {
    return this.status === NorceProductStatusCodes.Active;
  }

  get isComing(): boolean {
    return this.status === NorceProductStatusCodes.Coming;
  }

  get isExpired(): boolean {
    return this.status === NorceProductStatusCodes.Expired;
  }

  get isHidden(): boolean {
    return this.status === NorceProductStatusCodes.Hidden;
  }

  get standardStockStatus(): StockStatus | null {
    return this._ProductVariantRaw.stockStatuses.find(st => st.warehouse.code === NorceWarehouseCodes.Standard) ?? this._ProductVariantRaw.stockStatuses[0] ?? null;
  }

  get stockValue() {
    return this.standardStockStatus?.inStock ?? 0;
  }

  get url() {
    return this._ProductVariantRaw.url;
  }

  get hasIncomingDate() {
    let result = false;
    const today = new Date().toISOString().split('T')[0];
    this._ProductVariantRaw.stockStatuses?.forEach((f)=> {
      if (f.estimatedDelivery && f.estimatedDelivery > today) {
        result = true;
      }
    });
    return result;
  }

  get incomingDate() {
    const today = new Date().toISOString().split('T')[0];
    const dates = this._ProductVariantRaw.stockStatuses
      .filter((f) => f.estimatedDelivery && f.estimatedDelivery > today)
      .map((m) => m.estimatedDelivery.split('T')[0])
      .sort();
    if (!dates.length) {
      return null;
    }
    return dates.shift();
  }

  get isSoldOut() {
    return !this.stockValue && !this.incomingDate;
  }

  get isTempSoldOut() {
    return this.isActive && this.stockValue < 1 && this.hasIncomingDate;
  }

  get flags() {
    return this._ProductVariantRaw.flags ?? [];
  }

  get isPreOrderProduct() {
    return (this.isComing || this.isActive) && this.hasIncomingDate && this.flags.filter((flag: Flag) => stripFlagSuffix(flag.type) === NorceFlagCodes.PreOrder)?.length > 0;
  }

  get isOnDemandProduct() {
    return this.isActive && this.flags.filter((flag: Flag) => stripFlagSuffix(flag.type) === NorceFlagCodes.OnDemand)?.length > 0;
  }

  get productHasExpired() {
    return (this.isActive || this.isExpired || this.isHidden) && this.flags.filter((flag: Flag) => stripFlagSuffix(flag.type) === NorceFlagCodes.DiscontinuedAndVisible)?.length > 0;
  }

  get percentageCampaigns() {
    return this._ProductVariantRaw.flags.filter((f) => stripFlagSuffix(f.groupCode) === NorceFlagGroupCodes.PercentageCampaigns);
  }
  get stepCampaigns() {
    return this._ProductVariantRaw.flags.find((f) => stripFlagSuffix(f.groupCode) === NorceFlagGroupCodes.StepCampaigns);
  }
  get giftCampaigns() {
    return this._ProductVariantRaw.flags.find((f) => stripFlagSuffix(f.groupCode) === NorceFlagGroupCodes.GiftCampaigns);
  }

  get hasBuyMoreCampaign() {
    return this.stepCampaigns || this.percentageCampaigns.length > 0;
  }

  get hasFixedRecommendedQty() {
    return this._ProductVariantRaw.isRecommendedQtyFixed && (this._ProductVariantRaw.recommendedQty && this._ProductVariantRaw.recommendedQty > 0);
  }

  get isInStock() {
    return this.stockValue > 0;
  }

  get canAddToCart() {
    return useGlobalContentStore().getAllowBackorders || this.isInStock || this.isPreOrderProduct || this.isOnDemandProduct;
  }

  get isOnSaleForUser() {
    return false; // MOCK
  }

  get volume() {
    return this.parametricByCode(NorceParametricCodes.Volume) || null;
  }

  get ingredients() {
    return this._ProductVariantRaw.parametrics?.find((p) => p.code === NorceParametricCodes.Ingredients)?.value ??
      this._ProductVariantRaw.productParametrics?.find((p) => p.code === NorceParametricCodes.Ingredients)?.value ?? null;
  }

  /**
   * @deprecated -  use related products on product level, not variant
   */
  relatedProductsByType(type: string) {
    console.log('Using deprecated method - always return empty. Type: ' + type);
    return [];
  }
}
