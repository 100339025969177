import { useUiStore } from '~/store/ui';
import { StoryBlokLimitVisitorTypePlugin, StoryBlokNestableNav } from '~/constants/types/storyblok';
import { useUserStore } from '~/store/user';
import useStoryblokAdmin from '~/composeables/useStoryblokAdmin';
export default function useMenuHelper() {
  const userStore = useUserStore();
  const uiStore = useUiStore();

  const router = useRouter();
  const clickMenu = (url:string, children:boolean, _uid:string, ignoreParent?:  boolean) => {
    if (children) {
      uiStore.openSideMenuWith({ uid: _uid, ignoreParent });
    } else {
      router.push({ path: `${url}` });
    }
  };
  const clickMenuDesktop = (url:string, children:boolean, _uid:string, ignoreParent?:  boolean) => {
    if (children) {
      uiStore.openDesktopMenuWith({ uid: _uid, ignoreParent });
    } else {
      router.push({ path: `${url}` });
    }
  };

  /**
   * For some reason, pinia has converted my array to proxyobj
   */
  const checkDesign = (obj: any, check: string) => {
    if (typeof obj !== 'object') {
      return false;
    } else {
      return Object.values(obj).includes(check);
    }
  };

  const checkPermission = (nav: StoryBlokLimitVisitorTypePlugin | undefined) => {
    const storyblokAdmin = useStoryblokAdmin();
    if (storyblokAdmin.isInStoryblokAdmin()) {
      return true;
    }
    let output = true;
    if (nav) {
      if (
        nav.excludeUser
        && !userStore.loggedIn
        && !userStore.isCustomerSuccessUser
        && !userStore.isSalesRepUser
      ) {
        output = false;
      }

      if (
        nav.excludeUserLoggedIn
        && userStore.loggedIn
        && !userStore.isCustomerSuccessUser
        && !userStore.isSalesRepUser
      ) {
        output = false;
      }

      if (
        nav.excludeSupport
        && userStore.isCustomerSuccessUser
      ) {
        output = false;
      }

      if (
        nav.excludeSales
        && userStore.isSalesRepUser
      ) {
        output = false;
      }

    }

    return output;
  };

  return {
    checkDesign,
    clickMenu,
    clickMenuDesktop,
    checkPermission,
  };
}
