export type DomainMapper = {
  algoliaIndex: string,
  algoliaIndexAutocomplete: string,
  algoliaIndexPages: string,
  applicationId: string|number,
  canonicalUrl?: string,
  countryCode: string,
  currency: string,
  domain: string,
  environment: string,
  /**
   * base name of file, example "vardvaskan".
   * Logo should have 2 variants vardvaskan.svg (black) and vardvaskan-inv.svg (white) in public/logos/
   */
  flagUrl: string,
  /**
   * Primary site in a language, default (not set) is false
   * Eiter b2b or b2c, not both
   * for hreflang links
   * @important - this will match first found in language
   * so DON'T have this pointing to a dev/stage site in production
   */
  languagePrimary?: boolean,
  locale: string, // used to load correct file in /lang/{locale}.json
  logoUrl:string,
  noRobots?: boolean, //  false if not set
  productBasePath: string,
  sitemapUrl: string,
  storyblokBasePath: string,
  url: string,
  /**
   * If the host name has languages in path, example /se /no
   *
   */
  subFolder: boolean;
  keywordSuffix?: string,
  allowBackorders?: boolean,
}

const domainMap = [
  // LOCAL
  {
    countryCode: 'se',
    currency: 'SEK',
    domain: 'localhost:3011',
    environment: 'local',
    flagUrl: '/flags/1x1/no.svg',
    languagePrimary: true,
    locale: 'sv',
    logoUrl: 'session',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: '',
    url: 'https://localhost:3011/',
    algoliaIndex: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products',
    algoliaIndexAutocomplete: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_',
    applicationId: 1044,
    subFolder: false,
  },
  // STAGE
  {
    countryCode: 'se',
    currency: 'SEK',
    domain: 'session-stage.iveo.store',
    environment: 'preview',
    flagUrl: '/flags/1x1/no.svg',
    languagePrimary: true,
    locale: 'sv',
    logoUrl: 'session',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: '',
    url: 'https://session-stage.iveo.store/',
    algoliaIndex: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products',
    algoliaIndexAutocomplete: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_',
    applicationId: 1044,
    subFolder: false,
  },
  // PREVIEW
  {
    countryCode: 'se',
    currency: 'SEK',
    domain: 'preview.session.iveo.store',
    environment: 'preview',
    flagUrl: '/flags/1x1/no.svg',
    languagePrimary: true,
    locale: 'sv',
    logoUrl: 'session',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: '',
    url: 'https://preview.session.iveo.store/',
    algoliaIndex: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products',
    algoliaIndexAutocomplete: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_',
    applicationId: 1037,
    subFolder: false,
    allowBackorders: false,
  },
  // PRODUCTION
  {
    countryCode: 'se',
    currency: 'SEK',
    domain: 'session.iveo.store',
    environment: 'production',
    flagUrl: '/flags/1x1/no.svg',
    languagePrimary: true,
    locale: 'sv',
    logoUrl: 'session',
    noRobots: true,
    productBasePath: 'produkt',
    sitemapUrl: 'https://www.example.com/se-sitemap.xml',
    storyblokBasePath: '',
    url: 'https://session.iveo.store/',
    algoliaIndex: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products',
    algoliaIndexAutocomplete: '62130aa0-251c-4d9d-9419-08197ae7a0e2-sv-SE-products_query_suggestions',
    algoliaIndexPages: 'content_',
    applicationId: 1037,
    subFolder: false,
    allowBackorders: false,
  },
] as DomainMapper[];

export const findDomainByHostAndPath = (hostname: string|undefined, path: string|undefined): DomainMapper | null => {
  let foundDomain = null as null | DomainMapper;
  const foundDomains = domainMap.filter((d: { domain: string; subFolder: boolean; }) => d.domain === hostname);
  if (foundDomains.length > 1) {
    const nonSubFolderDomain = foundDomains.find((d: { subFolder: boolean; }) => !d.subFolder);
    if (nonSubFolderDomain) {
      foundDomain = nonSubFolderDomain;
    } else {
      path = path?.split('/').filter((str) => str.length).shift();
      const foundPathDomainMap = foundDomains.find((d: { storyblokBasePath: string | undefined; }) => d.storyblokBasePath === path) || null;
      if (foundPathDomainMap) {
        foundDomain = foundPathDomainMap;
      } else {
        foundDomain = foundDomains[0];
      }
    }

  } else {
    foundDomain = foundDomains[0] ?? domainMap[0] ?? null;
  }
  return foundDomain;
};

/**
 * Fallback strategies
 * If host name is matched but not language, ex /fi but we only have /se /no,
 * it will fall back to the first entry, so place fallback language first
 * We need a fallback language to display 404 page
 */

export default domainMap as DomainMapper[];
